<template>
    <div class="my-shop">
        <!-- <div class="my-shop__header">
            <div class="shopping">
                <span>购物车</span>
            </div>
            <div class="settlement" @click="goShopCar">
                结算支付
            </div>
        </div> -->
        <van-sticky>
            <div class="my-shop__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    show-action
                    shape="round"
                    placeholder="请输入商品名称"
                    @click="goSearch"
                    >
                    <template #action>
                        <div @click="goSearch">搜索</div>
                    </template>
                </van-search>
            </div>
        </van-sticky>

        <div class="content">
            <van-sticky>
                <div class="left">
                    <div class="line" :class="{'action': action === 1}" @click="changeAction(1)">
                        马术装备
                    </div>
                    <div class="line" :class="{'action': action === 2}" @click="changeAction(2)">
                        马术服装
                    </div>
                    <div class="line" :class="{'action': action === 3}" @click="changeAction(3)">
                        场地租赁
                    </div>
                    <div class="line" :class="{'action': action === 4}" @click="changeAction(4)">
                        婚纱摄影
                    </div>
                    <div class="line" :class="{'action': action === 5}" @click="changeAction(5)">
                        其他商品
                    </div>
                </div>
            </van-sticky>
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading1"
                    :finished="finished1"
                    @load="onLoad"
                    class="my-list"
                    >
                    <my-item v-for="item in list" :key="item.id" @clickEvent="addShop(item)">
                        <div class="info" slot="info">
                            <div class="horse">装备名称</div>
                            <div class="horse">装备名称</div>
                        </div>
                        <div class="appoitment money" slot="appoitment">
                            123元
                            <div class="icon">
                                <span>购</span>
                            </div>
                        </div>
                    </my-item>
                    <no-more v-show="!loading1"/>
                </van-list>
            </van-pull-refresh>
        </div>
        <van-popup class="my-popup" v-model="showGoods">
            <div class="goods">
                <div class="title">
                    马术装备
                </div>
                <div class="goods-info">
                    <div class="goods-info__img">
                        <van-image class="my-image" fit="fill" :src="imgUrl1" alt=""/>
                        <!-- <i class="iconfont icon-xiazai"></i> -->
                    </div>
                    <div class="goods-info__desc">
                        <div class="money">
                            130元
                        </div>
                        <div class="desc">
                            装备名称装备名称装备名称装备名称装备名称装备名称
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <div class="car" @click="addCar">加入购物车</div>
                    <div class="close" @click="showGoods = false">关闭</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
import { Toast } from 'vant';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            imgUrl1: require('@/assets/img/horse1.jpg'),
            action: 1,
            value: '',
            showGoods: false
        }
    },
    methods: {
        init() {
            this.initList()
        },
        goSearch() {
            this.$router.push('/allshop')
        },
        changeAction(data) {
            this.action = data
            //this.init()
        },
        addShop(data) {
            this.showGoods = true
            console.log(data)
        },
        addCar() {
            setTimeout(()=>{
                Toast('加入购物成功');
                this.showGoods = false
            }, 1000)
        },
        goShopCar() {
            this.$router.push('/shopping')
        }
    },
    mounted() {
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-shop{
        &__header{
            display: flex;
            margin: 10px 20px;
            background-color: $yellow;
            padding: 15px;
            border-radius: 19px;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            .shopping{
                font-size: 28px;
                position: relative;
                .my-badge{
                    position: absolute;
                    top: 4px;
                    left: 72px;
                }
            }
            .settlement{
                padding: 8px 12px;
                background-color: #fff;
                color: $main;
                border-radius: 9px;
           }
        }
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        .content{
            display: flex;
            justify-content: space-between;
            .van-sticky--fixed{
                right: unset;
                top: 60px;
            }
            .left{
                flex: 1;
                position: fixed;
                height: calc(100vh - 105px);
                overflow: scroll;
                z-index: 888;
                .line{
                    width: 20px;  
                    margin: 0 auto;  
                    line-height: 15px;  
                    font-size: 14px;
                    padding: 10px;
                    color: $main;
                    opacity: .3;
                    
                }
                .action {
                    opacity: 1 !important;
                }
            } 
            .my-pull-refresh{
                flex: 7;
                .my-list{
                    margin-top: 10px;
                    padding: 0 25px 0 0;
                    margin-left: 40px;
                }
            }

        }
        .my-popup{
            border-radius: 19px;
            .goods {
                height: 65vh;
                width: 280px;
                border-radius: 19px;
                padding: 20px;
                &-info{
                    margin-top: 20px;
                    &__img{
                        border-radius: 19px;
                        img{
                            border-radius: 19px;
                        }
                    }
                    &__desc{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 10px;
                        .money{
                            width: 100px;
                            padding: 10px;
                            border-radius: 9px;
                            background-color: $main;
                            color: #fff;
                            font-weight: bold;
                            text-align: center;
                        }
                        .desc{
                            margin-left: 20px;
                            font-size: 12px;
                             display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp:2;/**显示的行数**/
                            overflow:hidden;/**隐藏超出的内容**/
                        }
                    }
                }
            }
            .btn{
                margin-top: 30px;
                .car{
                    background-color: $yellow;
                    border-radius: 9px;
                    text-align: center;
                    padding: 10px 0;
                    font-size: 16px;
                    //color: #fff;
                    //font-weight: bold;
                }
                .close{
                    text-align: center;
                    padding: 10px 0;
                    margin-top: 20px;
                }
            }
            
        }
    }
</style>